import React, { useState, useEffect } from "react"
import { Container, Tab, Tabs } from "react-bootstrap"

import "../MarketInsights/MarketInsight.scss"
import InsightContent from "../MarketInsights/Content"

const MarketInsights = ({ MarketTitle, tabs }) => {
  //console.log("tabs", tabs)
  
  return (
    <Container fluid className="insights">
      <div className="market-insight">
        <h2 className="title">{MarketTitle}</h2>
        <Tabs
          defaultActiveKey={0}
          id="uncontrolled-tab-example"
          className="mb-3 ul-list"
        >
          {tabs.map((items, k) => {
            return (
              <Tab eventKey={k} title={items.Title} tabClassName="tab-item">
                <InsightContent {...items} />
              </Tab>
            )
          })}
        </Tabs>
      </div>
    </Container>
  )
}
export default MarketInsights

import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import "../MarketInsights/Content.scss"
import Img from 'gatsby-image';

const InsightContent = ({ Content, market_insights }) => {
  //console.log("insightprops",props)
  return (
    <div className="insight-content-block">
      <p className="insight-content">{Content}</p>
      <Row className="price-block">
      {market_insights.map((item, k) => {
        return (
          <Col key={k} className="col-md-4 item-block">
            <div  className="item-image">
             <Img fixed={item?.Image?.childImageSharp?.fixed}/>
            </div>
              <p className="item-title">{item?.Title}</p>
              <p className="item-price">{item?.Prefix}{item?.Value}{item?.Suffix}</p>
              <p className="item-hint">{item?.Hint}</p>
          </Col>
       
        )
      })}
      </Row>
    </div>
  )
}
export default InsightContent

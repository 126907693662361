import React from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "gatsby"

import './Valuation.scss';


const Valuation = (props) => {
  return (
    <Container fluid className="container-background-valuation-block">
      <div>
        <h2 className="title">{props.Title}</h2>
        <div className="before-content">{props.Content_Before}</div>
      </div>
      <div className="valuation-block-container">
        <Form className="property-block form-valuation d-lg-flex justify-content-lg-center">
          <div className="field-wrap d-md-flex flex-md-wrap">
            <Form.Group controlId="formBasicEmail">
              <Form.Control className="search_field_text input-field-valuation" type="text" placeholder="Your Postcode" />
            </Form.Group>
            <div className="btn-wrap">
              <Link className="btn btn-success btn-valuation" to="/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester">Sell</Link>
            </div>
            <div className="btn-wrap">
              <Link className="btn btn-success btn-valuation" to="/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester">Let</Link>
            </div>
          </div>
        </Form>
      </div>
      <div className="after-content">{props.Content_After} <a href={`tel:${props.Phone_Number}`} className="tele-number">{props.Phone_Number}</a></div>
    </Container>
  );
}
export default Valuation;
import React from 'react';
import { Link } from "gatsby"
import { Breadcrumb } from 'react-bootstrap';
import "../OfficePageBreadCrumb/OfficePageBreadCrumb.scss";

const OfficePageBreadcrumb = (props) => {
    //console.log("ofcname",props)
   
    return(
        <div className='office-breadcrumb-section'>
        <div className="breadcrumbs">
        <Breadcrumb>
            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/contact/our-offices/">Contact</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/contact/our-offices/">Our Offices</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/contact/our-offices/london-offices/">London Offices</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="">{props?.officeName}</Link></Breadcrumb.Item>

        </Breadcrumb>
    </div>
    </div>
    ) 
    
}
export default OfficePageBreadcrumb
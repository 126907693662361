import React from "react";
import { Container, Row , Col } from "react-bootstrap";
 import { Link } from "gatsby"

import './ContactCard.scss';

import PhoneIcon from "../../images/Phone.png"


const ContactCard = (props) => {
    //console.log("carddddd",props)
  return (
    <Container fluid className="contact-card-section">
        <h2 className="section-title">{props?.SectionTitle}</h2>
        <Row className="contact-card-block">
            {
                props.ContactCard.map((card,i) => {
                    console.log("card",card)
                    return(
                       <Col className= "col-xs-12  col-md-6">
                           <div className="card-details-block">
                            <h2 className="category">{card?.Category}</h2>
                            <div className="contact-block">
                               <img src={PhoneIcon} alt="phone-icon"/>
                               <a href={`tel:${card?.Phone_Number}`} className="contact-number">{card?.Phone_Number}</a>
                            </div>
                            
                            <Link to={card?.Property_Result_URL} className="property-result-link">{card?.Property_Result_CTA}</Link>
                            <div className="btn-wrap cta-section">
                                <a href={card?.Contact_CTA_URL} className="btn btn-default">{card?.Contact_CTA_Label}</a>

                                <Link to={card?.Valuation_CTA_Link} className="btn btn-success">{card?.Valuation_CTA_Label}</Link>
                            </div>
                            </div>
                          
                       </Col>
                    );
                })
            }
        </Row>
        
    </Container>
  );
}
export default ContactCard;